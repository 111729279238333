.uib-datepicker .uib-title {
  width: 100%;
}

.uib-day button, .uib-month button, .uib-year button {
  min-width: 100%;
}

.uib-left, .uib-right {
  width: 100%
}
