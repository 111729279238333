.uib-position-measure {
  display: block !important;
  visibility: hidden !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.uib-position-scrollbar-measure {
  position: absolute !important;
  top: -9999px !important;
  width: 50px !important;
  height: 50px !important;
  overflow: scroll !important;
}

.uib-position-body-scrollbar-measure {
  overflow: scroll !important;
}